/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TreeElement } from './treeElement';


/**
 * Customer account for the tree view.
 */
export interface TreeAccountElement { 
    /**
     * id of the organization
     */
    organizationId: string;
    /**
     * name of the organization
     */
    name: string;
    /**
     * array of subdirectories
     */
    subdirectories: Array<TreeElement>;
}

