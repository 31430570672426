/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HierarchyElement } from './hierarchyElement';


/**
 * List of directories and files inside a directory.
 */
export interface DirectoryContent { 
    /**
     * Current hierarchy path
     */
    path: string;
    /**
     * List of elements inside a directory.
     */
    content: Array<HierarchyElement>;
    /**
     * total records amount
     */
    totalRecords: number;
}

