import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import { DirectoryContent, DocumentApiService, TreeElement, TreeRootElement } from '../../api/v1';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  bookmarkedFilter = 'bookmarked';

  constructor(public api: DocumentApiService) {}

  getTreeView(): Observable<TreeNode[]> {
    const tree = this.api.getTree();
    return tree.pipe(map((treeData) => this.mapTree(treeData)));
  }

  getBookmarks(settings?: FilterTableSettings): Observable<DirectoryContent> {
    return this.api.listBookmarked(
      this.getSearchValue(settings),
      this.getSortBy(settings),
      this.getSortDirection(settings),
      this.getPage(settings),
      this.getPageSize(settings)
    );
  }

  globalSearch(searchTerm: string): Observable<DirectoryContent> {
    return this.api.showDirectory('', searchTerm);
  }

  showDirectory(path: string, settings?: FilterTableSettings): Observable<DirectoryContent> {
    return this.api.showDirectory(
      path,
      this.getSearchValue(settings),
      this.getSortBy(settings),
      this.getSortDirection(settings),
      this.getFilterBookmarks(settings),
      this.getPage(settings),
      this.getPageSize(settings)
    );
  }

  bookmark(path: string, bookmarked: boolean): Observable<unknown> {
    return this.api.bookmark(path, { bookmarked });
  }

  downloadBinary(path: string): Observable<Blob> {
    return this.api.download(path);
  }

  // Observable returns any because the API returns a 204 No Content
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(path: string): Observable<any> {
    return this.api.deleteDocument(path);
  }

  private getSortBy(settings: FilterTableSettings | undefined): string | undefined {
    if (!settings) {
      return undefined;
    }
    const sortByColumn = Object.keys(settings.columns).find((key) => settings.columns[key].sort);
    return sortByColumn ?? undefined;
  }

  private getSortDirection(settings: FilterTableSettings | undefined): string | undefined {
    if (!settings) {
      return undefined;
    }

    const sortByColumn = this.getSortBy(settings);

    if (sortByColumn) {
      return settings.columns[sortByColumn].sort as string;
    }

    return undefined;
  }

  private getSearchValue(settings: FilterTableSettings | undefined): string | undefined {
    if (settings?.search && settings?.searchValue != null) {
      return settings.searchValue;
    }
    return undefined;
  }

  private getPage(settings: FilterTableSettings | undefined): number | undefined {
    if (settings?.page != null && settings?.pageSize != null) {
      return settings.page;
    }
    return undefined;
  }

  private getPageSize(settings: FilterTableSettings | undefined): number | undefined {
    if (settings?.page != null && settings?.pageSize != null) {
      return settings.pageSize;
    }
    return undefined;
  }

  private getFilterBookmarks(settings?: FilterTableSettings): boolean | undefined {
    if (!settings) {
      return undefined;
    }

    return Object.keys(settings.columns).some((key) => settings.columns[key]?.filter?.[0] === this.bookmarkedFilter)
      ? true
      : undefined;
  }

  private mapTree(treeData: TreeRootElement): TreeNode[] {
    if (treeData.accounts.length === 0) {
      return [];
    }

    return treeData.accounts.map((account) => ({
      key: account.organizationId ?? '',
      label: account.name ?? account.organizationId ?? '',
      type: 'orga',
      children: account.subdirectories.map((node) => this.mapElement(node)),
    }));
  }

  private mapElement(folder: TreeElement): TreeNode {
    const node: TreeNode = {
      key: folder.path,
      label: folder.name,
    };
    if (folder.subdirectories.length) {
      node.children = folder.subdirectories.map((node) => this.mapElement(node));
    }
    return node;
  }
}
