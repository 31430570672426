<geaui-icon-v2
  class="bookmark-renderer"
  *ngIf="rowData && !rowData.bookmarked"
  [clickable]="true"
  [color]="'dark-blue'"
  (click)="onBookmarkChange(); $event.stopPropagation()"
  file="16px_bookmark"></geaui-icon-v2>

<geaui-icon-v2
  class="bookmark-renderer"
  *ngIf="rowData && rowData.bookmarked"
  [clickable]="true"
  [color]="'dark-blue'"
  (click)="onBookmarkChange(); $event.stopPropagation()"
  file="16px_bookmark_filled"></geaui-icon-v2>
