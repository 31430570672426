import { Component } from '@angular/core';
import { ComplexDialogEmbeddedView, ComplexDialogV2Service } from '@gea/digital-ui-lib';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'advance-new-folder-popup-content',
  templateUrl: './new-folder-popup-content.component.html',
})
export class NewFolderPopupContentComponent implements ComplexDialogEmbeddedView {
  value = '';
  newFolderForm!: FormGroup;
  constructor(
    private complexDialogV2Service: ComplexDialogV2Service,
    private formBuilder: FormBuilder
  ) {
    this.initializNewFolderForm();
  }

  onAcceptClick() {
    this.complexDialogV2Service.emitDataOutputForComponent(this.value);
  }

  initializNewFolderForm() {
    this.newFolderForm = this.formBuilder.group({
      newFolder: new FormControl<string>('', []),
    });
  }

  changeValue(value: string) {
    this.value = value;
  }
}
