import { Component, Inject, OnInit } from '@angular/core';
import { DocumentService } from '../services/document.service';
import { Router } from '@angular/router';
import { ApiErrorResponse, DialogV2Service, ErrorHandlerV2Service, SnackbarService } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../../environments/models/environment.model';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { PermissionsService } from '../services/permissions.service';
import { Permission } from '../../api/v1';
import { UserPermission } from '../models/permissions.model';

@Component({
  selector: 'advance-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss'],
})
export class DetailViewComponent implements OnInit {
  blobUrl?: string;
  path?: string;
  backButtonPath?: string;
  name?: string;
  loading = true;
  userPermission?: UserPermission;
  routerSubscription: Subscription | null = null;

  constructor(
    private documentService: DocumentService,
    private router: Router,
    private dialogService: DialogV2Service,
    private location: Location,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private errorhandlerService: ErrorHandlerV2Service,
    private permissionsService: PermissionsService,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {}

  ngOnInit(): void {
    this.path = this.router.url.split('/').pop();
    this.name = decodeURI(this.path?.split('%2F').pop() || '');
    this.documentService.downloadBinary(this.path ?? '').subscribe({
      next: (blob) => {
        this.blobUrl = URL.createObjectURL(blob);
        this.loading = false;
      },
      error: () => {
        this.dialogService.open({
          title: 'UI-LIB.ROUTE-GUARD.TITLE',
          message: this.translateService.instant('UI-LIB.ROUTE-GUARD.CONTENT') as string,
          yes: 'UI-LIB.ROUTE-GUARD.BACK-TO-DASHBOARD',
          no: '',
          closable: true,
          hideButtons: false,
          showRejectButton: false,
          confirmCallback: this.confirmNavigateToPortal.bind(this),
        });
      },
    });
    this.generateBackButtonPath();
    this.setUserPermissions();
  }

  navigateToList(): void {
    void this.router.navigate([this.backButtonPath]);
  }

  generateBackButtonPath() {
    const path = decodeURIComponent(this.location.path());
    this.backButtonPath = path.substring(path.indexOf('/', 1) + 1, path.lastIndexOf('/'));
  }

  copyLink(): void {
    void navigator.clipboard.writeText(window.location.href);
  }

  download(): void {
    const link = document.createElement('a');
    link.href = this.blobUrl ?? '';
    link.download = this.name ?? '';
    link.click();
  }

  delete(): void {
    this.dialogService.open({
      title: 'DOCUMENT.DELETE.CONFIRMATION.TITLE',
      message: this.translateService.instant('DOCUMENT.DELETE.CONFIRMATION.MESSAGE', {
        fileOrDirectory: this.name,
      }) as string,
      yes: 'DOCUMENT.DELETE.CONFIRMATION.REMOVE',
      no: 'DOCUMENT-LIST.POPUP.CANCEL',
      closable: true,
      hideButtons: false,
      showRejectButton: true,
      buttonTypeYes: 'cancel-red',
      confirmCallback: this.deleteFile.bind(this),
    });
  }

  confirmNavigateToPortal() {
    void this.router.navigate([this.environment.portal.redirectURL]);
  }

  checkUserPermission() {
    if (this.userPermission) {
      return this.userPermission.permissions.permissions.includes(Permission.WriteDocuments);
    }

    return false;
  }

  setUserPermissions() {
    const path = decodeURIComponent(this.location.path());
    const id = path.split('/')[path.startsWith('/') ? 2 : 1];
    this.permissionsService
      .getPermissions(id)
      .pipe(
        map((permissions) => {
          return { organization: id, permissions: permissions };
        })
      )
      .subscribe((permission) => {
        this.userPermission = permission;
      });
  }

  deleteFile() {
    if (this.path) {
      this.documentService.delete(decodeURIComponent(this.path)).subscribe({
        next: () => {
          void this.router.navigate([this.backButtonPath]);
          this.snackbarService.add({
            summary: this.translateService.instant('DOCUMENT.DELETE.SUCCESS.TITLE', {
              fileOrDirectory: this.name,
            }) as string,
            detail: this.translateService.instant('DOCUMENT.DELETE.SUCCESS.MESSAGE', {
              fileOrDirectory: this.name,
            }) as string,
            severity: 'success',
          });
        },
        error: (err: ApiErrorResponse) => {
          this.handleError(err);
        },
      });
    }
  }

  handleError(error: ApiErrorResponse) {
    this.errorhandlerService.handleError(error);
    this.loading = false;
  }
}
