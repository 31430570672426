/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Permission } from './permission';


/**
 * User permissions for a customer.
 */
export interface Permissions { 
    /**
     * List of permissions
     */
    permissions: Array<Permission>;
}

