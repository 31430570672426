import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Permissions, PermissionsApiService } from '../../api/v1';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private api: PermissionsApiService) {}

  getPermissions(customerNumber: string): Observable<Permissions> {
    return this.api.getPermissions(customerNumber);
  }
}
