/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Metadata } from './metadata';


/**
 * Single element inside a directory, can be a file or directory.
 */
export interface HierarchyElement { 
    /**
     * File or Directory name.
     */
    name: string;
    /**
     * hierarchy path
     */
    path: string;
    /**
     * File or Directory
     */
    resourceType: HierarchyElement.ResourceTypeEnum;
    /**
     * size of a file in bytes
     */
    size?: number;
    /**
     * content type of a file
     */
    content_type?: string;
    /**
     * is element bookmarked
     */
    bookmarked: boolean;
    /**
     * status of the file
     */
    status: HierarchyElement.StatusEnum;
    /**
     * key-value pairs for metadata
     */
    metadata: Array<Metadata>;
    /**
     * Name of the organization
     */
    organizationName?: string;
    /**
     * Id of the organization
     */
    organizationId: string;
    /**
     * File type ending
     */
    fileEnding?: string;
}
export namespace HierarchyElement {
    export type ResourceTypeEnum = 'file' | 'directory';
    export const ResourceTypeEnum = {
        File: 'file' as ResourceTypeEnum,
        Directory: 'directory' as ResourceTypeEnum
    };
    export type StatusEnum = 'ok' | 'error';
    export const StatusEnum = {
        Ok: 'ok' as StatusEnum,
        Error: 'error' as StatusEnum
    };
}


