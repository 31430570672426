/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SharepointSite } from './sharepointSite';


/**
 * Organization with Sharepoint Site definitions
 */
export interface SharepointOrganization { 
    /**
     * Organization ID from GEA ID
     */
    organizationId?: string;
    sites: Array<SharepointSite>;
    /**
     * Customer number
     */
    customerNumber?: string;
}

