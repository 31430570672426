/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A Sharepoint Site definition
 */
export interface SharepointSite { 
    /**
     * sharepoint site Id
     */
    siteId: string;
    siteType: SharepointSite.SiteTypeEnum;
}
export namespace SharepointSite {
    export type SiteTypeEnum = 'project' | 'customer';
    export const SiteTypeEnum = {
        Project: 'project' as SiteTypeEnum,
        Customer: 'customer' as SiteTypeEnum
    };
}


