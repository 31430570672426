import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';

import { DocumentService } from '../../services/document.service';
import { HierarchyElement } from '../../../api/v1';

@Component({
  selector: 'advance-document-app-bookmark-renderer',
  templateUrl: './bookmark-renderer.component.html',
  styleUrls: ['./bookmark-renderer.component.scss'],
})
export class BookmarkRendererComponent implements ColumnRendererComponent<HierarchyElement> {
  rowData?: HierarchyElement;

  constructor(private documentService: DocumentService) {}

  onBookmarkChange() {
    if (!this.rowData) return;
    const path = (this.rowData.path.length > 1 ? this.rowData.path : '') ;
    this.documentService.bookmark(path, !this.rowData.bookmarked).subscribe({
      next: () => {
        if (this.rowData) {
          this.rowData.bookmarked = !this.rowData.bookmarked;
        }
      },
    });
  }
}
