import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { CreateFolder201Response, DocumentApiService, UploadFiles200Response } from '../../api/v1';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  maxFileSizeInBytes = 786432000;

  constructor(private api: DocumentApiService) {}

  addAttachment(attachments: File[], directory: string): Observable<UploadFiles200Response> {
    return this.api.uploadFiles(attachments, directory);
  }

  createFolder(directory: string, folderName: string): Observable<CreateFolder201Response> {
    return this.api.createFolder({ directory: directory, folderName: folderName });
  }
}
