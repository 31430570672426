import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { MsalGuard } from '@azure/msal-angular';

import { DetailViewComponent } from './home/detail-view/detail-view.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'detail-view/:path',
    component: DetailViewComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [MsalGuard],
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
