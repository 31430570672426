/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TreeAccountElement } from './treeAccountElement';


/**
 * root element of the directory tree
 */
export interface TreeRootElement { 
    /**
     * array of accounts
     */
    accounts: Array<TreeAccountElement>;
}

