/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Permission
 */
export type Permission = 'WRITE_DOCUMENTS' | 'READ_DOCUMENTS' | 'NONE';

export const Permission = {
    WriteDocuments: 'WRITE_DOCUMENTS' as Permission,
    ReadDocuments: 'READ_DOCUMENTS' as Permission,
    None: 'NONE' as Permission
};

