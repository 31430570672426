/**
 * Document app
 * Document app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateFolderRequest { 
    /**
     * Path of the directory where the folder will be created.
     */
    directory: string;
    /**
     * Name of the folder to be created.
     */
    folderName: string;
}

